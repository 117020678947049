import { graphql } from "gatsby"
import React, { Fragment } from "react"
import PropTypes from "prop-types"
import Plx from "react-plx"
import windowSize from "react-window-size"
import { MdArrowForward } from "react-icons/md"
import { hexToRGBA } from "gatsby-interface"
import { Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../../layouts"
import Container from "../../components/container"
import HeroContainer from "../../components/containers/hero"
import SEO from "../../components/seo"
import Header from "../../components/header"
import Footer from "../../components/footer"
import { linkStyles } from "../../utils/styles"
import { SkipNavTarget } from "../../components/shared/components/SkipNav"

import gatsbyDaysLogo from "!raw-loader!svgo-loader?{ 'plugins': [{ 'collapseGroups': false }, { 'removeTitle': false }, { 'cleanupIDs': false }] }!../../assets/gatsby-days-logo.svg"
import Dots from "./dots"

import Metaball from "../../components/metaball"

import { rhythm } from "../../utils/typography"

const styles = theme => ({
  sectionHeader: {
    color: theme.colors.lilac,
    fontFamily: theme.fonts.heading,
    fontSize: theme.fontSizes[1],
    textTransform: `uppercase`,
    letterSpacing: theme.letterSpacings.tracked,
    fontWeight: `normal`,
    marginTop: theme.space[10],
  },
  signupLink: {
    background: theme.colors.gatsby,
    border: `1px solid ${hexToRGBA(theme.colors.blue[10], 0.275, true)}}`,
    fontFamily: theme.fonts.heading,
    borderRadius: theme.radii[2],
    color: theme.colors.white,
    display: `inline-block`,
    fontWeight: `bold`,
    fontSize: theme.fontSizes[4],
    marginTop: theme.space[6],
    marginBottom: theme.space[7],
    padding: `${theme.space[3]} ${theme.space[6]}`,
    textDecoration: `none`,
    whiteSpace: `nowrap`,
    "& > svg": {
      verticalAlign: `text-bottom`,
    },
    [theme.mediaQueries.desktop]: {
      fontSize: theme.fontSizes[5],
    },
  },
  gatsbyDaysLogo: {
    maxWidth: rhythm(8),
  },
  headerLine: {
    position: `relative`,
    display: `inline-block`,

    "&:before": {
      content: `''`,
      width: `2000px`,
      height: `1px`,
      background: theme.colors.purple[20],
      position: `absolute`,
      left: `calc(100% + ${theme.space[6]})`,
      top: `50%`,
    },
  },
})

class GatsbyDaysListPage extends React.Component {
  render() {
    const { contentfulPage } = this.props.data
    const { eventSections } = contentfulPage
    const clientHeight = this.props.windowHeight || 1

    const previousEvents = []
    let upcomingEvent

    eventSections.forEach(event => {
      if (event.upcoming) {
        upcomingEvent = event
      } else {
        previousEvents.push(event)
      }
    })

    return (
      <Layout pathname={this.props.location.pathname}>
        <SEO contentfulPage={contentfulPage} url={this.props.location.href} />
        <Header />
        <main css={{ position: `relative`, width: `100%` }}>
          <SkipNavTarget />
          <Plx
            animateWhenNotInViewport
            parallaxData={[
              {
                duration: clientHeight * 1.5,
                offset: -clientHeight,
                properties: [
                  { endValue: 500, property: `translateY`, startValue: 0 },
                  { endValue: 0, property: `opacity`, startValue: 1 },
                ],
                start: `self`,
              },
            ]}
          />
          <Plx
            animateWhenNotInViewport
            parallaxData={[
              {
                duration: clientHeight * 1.5,
                offset: -clientHeight,
                properties: [
                  {
                    endValue: 200,
                    property: `translateY`,
                    startValue: 0,
                  },
                ],
                start: `self`,
              },
            ]}
          >
            <Metaball />
          </Plx>
          <HeroContainer>
            {upcomingEvent && upcomingEvent.image && (
              <div
                css={theme => ({
                  position: `absolute`,
                  top: `6rem`,
                  right: 0,
                  width: 120,
                  zIndex: 10,
                  [theme.mediaQueries.mobile]: { width: 160 },
                  [theme.mediaQueries.phablet]: { width: 200, top: `4rem` },
                  [theme.mediaQueries.tablet]: { width: 140, top: `2rem` },
                  [theme.mediaQueries.desktop]: { width: 180, top: `2rem` },
                  [theme.mediaQueries.hd]: { width: 330, top: `4rem` },
                })}
              >
                <Img fluid={upcomingEvent.image.fluid} />
                <Dots
                  css={{
                    overflow: `visible`,
                    position: `absolute`,
                    top: `-3%`,
                    right: `-18%`,
                    width: `100%`,
                    height: `auto`,
                  }}
                />
              </div>
            )}
            <Container>
              <div css={{ position: `relative`, zIndex: 100, width: `100%` }}>
                <Plx
                  animateWhenNotInViewport
                  parallaxData={[
                    {
                      duration: clientHeight * 1.5,
                      offset: -clientHeight,
                      properties: [
                        {
                          endValue: -50,
                          property: `translateY`,
                          startValue: 0,
                        },
                      ],
                      start: `self`,
                    },
                  ]}
                >
                  <div
                    css={theme => ({
                      display: `flex`,
                      flexWrap: `wrap`,
                      width: `100%`,
                      [theme.mediaQueries.tablet]: { flexWrap: `nowrap` },
                    })}
                  >
                    <div
                      css={theme => ({
                        marginRight: theme.space[9],
                        minWidth: `250px`,
                        width: `20%`,
                      })}
                    >
                      <h1
                        dangerouslySetInnerHTML={{ __html: gatsbyDaysLogo }}
                        css={theme => ({
                          ...styles(theme).gatsbyDaysLogo,
                          display: `block`,
                          svg: {
                            width: `100%`,
                            height: `auto`,
                          },
                        })}
                      />
                    </div>

                    <div
                      css={theme => ({
                        marginTop: theme.space[4],
                        maxWidth: `500px`,
                        [theme.mediaQueries.hd]: { maxWidth: `480px` },
                        [theme.mediaQueries.hhd]: { maxWidth: `680px` },
                      })}
                    >
                      {upcomingEvent && (
                        <Fragment>
                          <h5
                            css={theme => ({
                              ...styles(theme).sectionHeader,
                              marginTop: theme.space[4],
                              marginBottom: theme.space[8],
                              ...styles(theme).headerLine,
                            })}
                          >
                            Upcoming
                          </h5>
                          <h3
                            css={theme => ({
                              fontSize: theme.fontSizes[6],
                              margin: 0,
                            })}
                          >
                            <span
                              css={theme => ({
                                display: `block`,
                                color: theme.colors.purple[60],
                              })}
                            >
                              Gatsby Days
                            </span>
                            {upcomingEvent.location}
                          </h3>
                          <h2
                            css={theme => ({
                              fontSize: `3rem`,
                              fontWeight: 800,
                              lineHeight: 1,
                              marginTop: theme.space[4],
                              [theme.mediaQueries.desktop]: {
                                fontSize: `3.5rem`,
                              },
                            })}
                          >
                            {upcomingEvent.date}
                          </h2>
                          <div
                            css={theme => ({
                              fontFamily: theme.fonts.heading,
                              color: theme.colors.grey[70],
                              fontSize: theme.fontSizes[4],
                              lineHeight: 1.4,
                            })}
                            dangerouslySetInnerHTML={{
                              __html:
                                upcomingEvent.description.childMarkdownRemark
                                  .html,
                            }}
                          />
                          {upcomingEvent.ctaLink && upcomingEvent.ctaLink.url && (
                            <a
                              href={upcomingEvent.ctaLink.url}
                              css={theme => styles(theme).signupLink}
                            >
                              {upcomingEvent.ctaLink.displayTitle}

                              <MdArrowForward
                                css={{
                                  marginLeft: `.2em`,
                                  verticalAlign: `middle`,
                                }}
                              />
                            </a>
                          )}
                          <div
                            css={theme => ({
                              display: `flex`,
                              justifyContent: `space-between`,
                              div: {
                                width: `45%`,
                              },
                              h5: {
                                ...styles(theme).sectionHeader,
                                marginBottom: theme.space[4],
                              },
                              p: {
                                fontFamily: theme.fonts.heading,
                                color: theme.colors.grey.copy,
                              },
                            })}
                          >
                            {(upcomingEvent.venue ||
                              upcomingEvent.location) && (
                              <div>
                                <h5>Venue</h5>
                                <p>
                                  {upcomingEvent.venue}
                                  {` `}
                                  {upcomingEvent.venue && <br />}
                                  {` `}
                                  {upcomingEvent.location}
                                </p>
                              </div>
                            )}
                            {upcomingEvent.date && upcomingEvent.year && (
                              <div>
                                <h5>Date</h5>
                                <p>
                                  {upcomingEvent.date}, {upcomingEvent.year}
                                  <br />
                                  {upcomingEvent.time}
                                </p>
                              </div>
                            )}
                          </div>
                        </Fragment>
                      )}

                      <h2
                        css={theme => ({
                          ...styles(theme).sectionHeader,
                          marginBottom: 0,
                          ...styles(theme).headerLine,
                        })}
                      >
                        Past Gatsby Days
                      </h2>
                    </div>
                  </div>
                </Plx>
              </div>
            </Container>
          </HeroContainer>
          <Container>
            {previousEvents.map(event => (
              <Fragment key={event.id}>
                <section
                  css={theme => ({
                    display: `flex`,
                    marginTop: theme.space[9],
                    marginBottom: theme.space[13],

                    [theme.mediaQueries.desktop]: {
                      "&::before": {
                        content: `" "`,
                        marginRight: theme.space[9],
                        minWidth: `250px`,
                        width: `20%`,
                        flexShrink: 0,
                      },
                    },
                  })}
                >
                  <div>
                    <h3
                      css={theme => ({
                        color: theme.colors.lilac,
                        fontSize: theme.fontSizes[5],
                      })}
                    >
                      <small
                        css={theme => ({
                          color: theme.colors.blackFade[90],
                          display: `block`,
                          fontSize: theme.fontSizes[2],
                          marginBottom: theme.space[2],
                        })}
                      >
                        {event.year}
                      </small>
                      {event.date}, {event.location}
                    </h3>

                    <div
                      css={theme => ({
                        maxWidth: `680px`,
                        color: theme.colors.grey[70],
                        h3: {
                          display: `none`,
                        },
                      })}
                      dangerouslySetInnerHTML={{
                        __html: event.description.childMarkdownRemark.html,
                      }}
                    />
                    <Link
                      to={`/${contentfulPage.slug}/${event.associatedPage.slug}`}
                      css={theme => linkStyles(theme)}
                    >
                      Watch videos <MdArrowForward />
                    </Link>
                  </div>
                </section>
              </Fragment>
            ))}
          </Container>
        </main>
        <Footer />
      </Layout>
    )
  }
}

GatsbyDaysListPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  dimensions: PropTypes.object,
  windowHeight: PropTypes.number,
}

export default windowSize(GatsbyDaysListPage)

export const pageQuery = graphql`
  query ContentfulGatsbyDaysListPage($id: String!) {
    contentfulPage(id: { eq: $id }) {
      name
      slug
      title
      subtitle {
        subtitle
      }
      ...ContentBlocks
      ...SocialMediaImage
      eventSections {
        id
        title
        date
        location
        time
        year
        upcoming
        image {
          fluid(maxWidth: 400, quality: 90) {
            ...GatsbyContentfulFluid_noBase64
          }
        }
        ctaLink {
          displayTitle
          url
        }
        description {
          childMarkdownRemark {
            html
          }
        }
        associatedPage {
          slug
        }
        videos {
          page {
            title
            name
            slug
            ...ContentBlocks
          }
        }
      }
    }
  }
`
